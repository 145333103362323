body {
  background: #ffffff;
  min-height: 100vh;
}

#root {
  height: 100%;
  margin: 0 auto;
}

#main {
  height: 100vh;
}

#container {
  min-width: 100vw;
  display: grid;
  grid-auto-rows: auto;
  align-content: start;
  grid-template-areas:
    'header'
    'content'
    'footer';
}

#login-testing {
  margin-top: 20px;
}

#login-form {
  height: 100%;
}

#login-form .button {
  background-color: #008cff;
}

#login-form .grid.middle.aligned {
  height: 100%;
}

.route-component {
  width: 90%;
  margin: 20px auto 20px auto;
  --header-height: 52px;
  height: calc(100vh - var(--header-height) - 40px);
  max-width: 1400px;
  &.packages {
    max-width: 1500px;
    height: calc(100vh - var(--header-height) - 80px);
  }
}

.legacy-page {
  height: calc(100vh - var(--header-height) - 100px);
}

.paginated-table {
  height: calc(100vh - var(--header-height) - 80px);
}

.form-button-sked-blue {
  background-color: #008cff !important;
  color: white !important;
}

.back-button {
  color: #008cff;
}

#modal-header-add-team {
  color: #fff;
  background-color: #008cff;
}

#modal-header-install-package {
  color: #fff;
  background-color: #008cff;
}

#modal-header-add-login-option {
  color: #fff;
  background-color: #fff;
}

#modal-content-add-team {
  background-color: #f4f6f9;
}

#modal-button-add-team {
  margin-top: 25px;
  color: #fff;
  background-color: #008cff;
}

#modal-header-add-customer {
  color: #fff;
  background-color: #008cff;
}

#modal-content-add-customer {
  background-color: #f4f6f9;
}

#modal-button-add-customer {
  margin-top: 25px;
  color: #fff;
  background-color: #008cff;
}

#modal-submit-no-padding {
  color: #fff;
  background-color: #008cff;
}

/* Hacky semantic ui custom button styling - no tertiary/borderless buttons available! */
.ui.button.basic.modal-delete-menu,
.ui.button.basic.modal-delete-menu:hover,
.ui.button.basic.modal-delete-menu:focus {
  box-shadow: none !important;
  background: 0 !important;
  border-radius: 0 !important;
  border: 0 !important;
  color: red !important;
}

.ui.button.basic.modal-reset-menu,
.ui.button.basic.modal-reset-menu:hover,
.ui.button.basic.modal-reset-menu:focus {
  box-shadow: none !important;
  background: 0 !important;
  border-radius: 0 !important;
  border: 0 !important;
  color: black !important;
}

.ui.button.radio {
  text-align: left;
  padding: 14px;
}
.ui.button.radio.selected {
  border: 2px solid #008cff;
  padding: 12px;
}

.ui.button.datadog {
  padding: 2px !important;
}

i.icon.datadog {
  width: 26px;
  height: 26px !important;
}

.back-button .chevron.icon {
  color: #008cff;
}

#edit-customer-selector {
  border-color: #85b7d9;
}

.sked-blue-button .ui.button {
  background-color: #008cff;
  color: white;
}

.clickable {
  cursor: pointer;
}

.console {
  padding: 10px;
  font-family: 'monospace';
  max-width: 1000px;
  background: white;
  box-shadow: inset 0 0 1px #000000;
  white-space: pre-wrap;
  overflow-y: scroll;
  height: calc(100% - 150px);
  text-align: left;
}

.console span {
  white-space: pre-wrap;
}

.deployment-details {
  padding-left: 20px;
}

.deployment-details th {
  border-bottom: none;
}

.scrollable {
  overflow-y: auto;
  max-height: calc(100% - var(--header-height));
  margin: 15px auto 15px auto;
}

.scrollable-with-pagination {
  overflow-y: auto;
  max-height: calc(100% - var(--header-height) - 80px);
  margin: 15px auto 15px auto;
}

.scrollable-no-margin {
  overflow-y: auto;
  max-height: calc(100%);
}

.application-deployments {
  height: calc(100vh - 230px);
  min-height: 480px;
}

.table-cell-softwrap {
  overflow-wrap: anywhere;
}

.table-cell-ellipsis {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.table-cell-overflow {
  overflow-wrap: break-word;
  word-break: break-all;
}

.table-cell-no-wrap {
  white-space: nowrap;
}

.circle-icon-button {
  background-color: #008cff !important;
  color: white !important;
  padding: 0.3em !important;
}

.square-icon-button {
  padding: 0.5em !important;
}

.alert-toast {
  position: absolute;
  z-index: 1002;
  right: 10px;
  top: 10px;
  max-width: 35%;
}

.sticky-table th {
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: #f9fafb !important;
}

.action-menu {
  margin: 0;
  box-shadow: 0px 0px 3px 0px #6b7280 !important;
  font-weight: bold !important;
}

.overflow-hide {
  position: relative;
}

.overflow-hide:after {
  content: '';
  right: 0;
  top: 0;
  width: 50px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
  height: 100%;
  position: absolute;
}

.create-team-note {
  padding-bottom: 6px;
  color: #4a556a;
  font-size: 13px;
}

.create-team-header {
  margin-bottom: 6px !important;
}

.show {
  transition: all 0.2s ease-in-out;
}

.show.sf {
  height: 83px;
}

.show.sf.error {
  height: 129px;
}

.show.user {
  height: 280px;
}

.show.login {
  height: 193px;
}

.hide {
  height: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  margin: 0 !important;
}

#radio-logo {
  width: 22px;
  margin: 0;
  margin-right: 8px;
}

/* HEADER CSS */
#header {
  margin: 0;
  background-color: var(--bg);
  border: var(--border);
}

#header .column {
  padding: 0;
  display: flex;
  flex-direction: row;
  color: var(--text);
}

#header-col-1 {
  padding: 14px 20px !important;
  gap: 20px;
  justify-content: flex-start;
}

#header-col-2 {
  justify-content: center;
}

#header-col-3 {
  justify-content: flex-end;
}

#env-label {
  font-family: 'Ubuntu', sans-serif !important;
  padding: 4px 8px;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
}

#user-avatar {
  background-color: #5e687a;
  font-family: 'Ubuntu', sans-serif !important;
  width: 32px;
  height: 32px;
  align-content: center;
  line-height: 19px;
  font-size: 12px;
  margin: 0;
}

#header-info-menu {
  text-decoration: none !important;
  padding: 10px 10px !important;
}

#header-info-menu .item {
  text-decoration: none !important;
}

#header-info-menu .divider {
  margin: 0;
}

#settings-divider {
  border-left: 1px solid #cacfd9;
  margin: 10px 0;
}

#old-model-warning {
  font-size: 13px;
  letter-spacing: -0.13px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  #header-col-menu {
    text-align: left;
  }
}

/* LANDING CSS */
#landing-page {
  height: 100%;
  color: #223049;
}

#landing-page h4 {
  text-align: left;
}

#landing-page .row {
  padding: 0;
}

#landing-page .column {
  padding: 8px 8px;
}

#landing-page .button {
  padding: 20px;
}

#landing-nav-button {
  color: var(--text);
  background-color: var(--bg);
  border: 1px solid #cacfd9;
  font-weight: 500;
  text-align: left;
}

#landing-nav-button span {
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

#landing-nav-button .icon {
  text-align: right;
}

#landing-welcome {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.ui.small.statistics .statistic > .value.small {
  font-size: 1rem !important;
  vertical-align: middle;
  margin: 0 0 0 5px;
}

.ui.attached.tabular.menu {
  flex-wrap: wrap;
}

i.icon.lambda.circle::before {
  content: '\1D77A';
  color: white;
}

i.icon.lambda.circle.orange {
  background-color: orange;
  border-radius: 50%;
  width: 1em;
  height: 1em;
}

details summary {
  display: flex;
  justify-content: space-between;
  padding: 10px 1px;
}

details summary h3:before {
  content: '\25B6  '; /* right triangle */
  font-size: 12px;
  padding-right: 3px;
}

details[open] summary h3:before {
  content: '\25BC  '; /* down triangle */
}

div.ftb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div.ftb-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  div.ftb-toggles {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-left: 30px;
  }

  div.ftb-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    gap: 10px;
  }
}

div.page-content {
  display: flex;
  grid-area: content;
  margin: 20px;
  gap: 20px;
  flex-wrap: wrap;

  div.breadcrumb {
    flex: 0 0 100%;
  }

  h2 {
    flex: 0 0 100%;
  }

  div.page-content-sidebar {
    flex: 20;
  }

  div.page-content-main {
    flex: 80;
  }
}

nav.page-header {
  display: flex !important;
  grid-area: header;
  justify-content: space-between;
  align-items: center;
  background-color: initial;
  border: none;
  box-shadow: none;
  font-size: 14px;

  div.page-header-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 24px !important;
    min-height: 18px !important;
    width: 20%;

    img {
      padding: 10px;
    }
  }

  div.page-header-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    justify-content: right;
    padding-left: 0;

    ul {
      padding: 0;
      margin: 0;

      .horizontal-line {
        color: lightgrey;
      }
    }
    li {
      cursor: pointer;
      padding: 10px;
      color: #223049;

      a {
        color: inherit;
      }
    }
  }

  h1 {
    margin: 0;
  }

  ul.page-header-links {
    li {
      display: inline-block;
    }

    li + li:before {
      content: '  ';
      padding: 0 10px;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  a.item,
  a.settings {
    background: none;
    color: inherit;
  }

  a.item::before {
    background: none;
  }

  a.item:hover {
    text-decoration: underline;
  }

  a.active.item {
    text-decoration: underline;
  }
}

nav.page-header.applications,
#landing-nav-button.applications {
  background-color: #223049;
  color: white;
}

nav.page-header.old-model,
#landing-nav-button.old-model {
  background-color: #f3f5f9;
  color: #223049;
}
nav.page-header.default,
#landing-nav-button.default {
  background-color: white;
  color: #223049;
}
nav.page-header.default.border {
  border: 1px solid lightgray;
}
div.horizontal-line {
  border-top: 1px solid;
  padding: 1px 10px;
}

div.vertical-line {
  border-left: 1px solid;
  margin-left: 10px;
  padding: 10px 1px;
}

.centered {
  text-align: center;
}

/* This is a tiny set of util classes in Tailwind format */
/* if we need more utils we might add the whole suite to the project */
.tw-pt-4 {
  padding-top: 1rem;
}
.tw-pl-8 {
  padding-left: 2rem;
}

.tw-bg-red-100 {
  background-color: #f7d8d6;
}
